<template>
  <transition name="fade">
    <div class="loading" id="loading" v-if="loadMask">
      <div class="loading-box">
        <div class="loading-img">
          <img src="@/assets/img/logo.svg" alt="" />
        </div>
        <span>LOADING...</span>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Loading",
  props: ["loadMask"],
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>