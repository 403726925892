<template>
  <div>
    <script type="application/ld+json">
      {
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "746山海誌",
        "image": "http://shttp://192.168.50.54:8080/img/book_cover.dcab3c98.png",
        "description": "這是一座被山谷、海洋環繞的島嶼，這座島上，共有746個原住民部落，<BR/>我們以山海為家，孕育出豐沛的人文歷史。"
      }
    </script>
    <Loading :loadMask="isLoad" />
    <div class="m-warning">
      <img
        src="@/assets/img/mobile.svg"
        alt=""
      />建議使用直式瀏覽，<br />以獲得最佳瀏覽體驗。
    </div>
    <section class="ind-wrap">
      <Logo />
      <!-- <a class="logo" href="index.html"
        ><img src="@/assets/img/logo.svg" alt=""
      /></a> -->
      <NavArea />
      <div class="font-sty ipcf-text">
        INDIGENOUS PEOPLES CULTURAL FOUNDATION
      </div>
      <div class="font-sty return-home">RETURN HOME</div>
      <div class="side-cricle">
        <img src="@/assets/img/cricle_1.png" alt="" />
      </div>
      <!-- <div class="side-text">
        746代表的是台灣原住民部落總數<br />這是一座被山谷、海洋環繞的島嶼，<br />這座島上，共有746個原住民部落，<br />我們以山海為家，孕育出豐沛的人文歷史<br />這裡，有著我們用生命刻劃的足跡<br />這裡，有著我們用文化寫下的印記<br />746山海誌，用影像、用文字，<br />紀錄下我們在這座島上的點滴
      </div> -->
      <div class="txt-group">
        <div class="line-1"></div>
        <div class="line-2"></div>
        <!-- <h1 class="font-sty book-title">山海誌</h1> -->
        <!-- <h2 class="font-sty book-sub-title">
          MOUNTAIN<br />AND<br />SEA<br />LOG
        </h2> -->
        <div class="font-sty name-culture">CULTURE</div>
        <div class="font-sty name-food">FOOD</div>
        <div class="font-sty number">746</div>
        <div class="font-sty name-traditional">TRADITIONAL</div>
        <div class="font-sty name-botanical">BOTANICAL</div>
      </div>
      <div class="side-text2">
        746代表的是台灣原住民部落總數，這是一座被山谷、海洋環繞的島嶼，這座島上，共有746個原住民部落，<br />我們以山海為家，孕育出豐沛的人文歷史。這裡，有著我們用生命刻劃的足跡、這裡，有著我們用文化寫下的印記。<br />746山海誌，用影像、用文字，紀錄下我們在這座島上的點滴
      </div>
      <div class="mask-box">
        <div class="video-full-box-in">
          <video autoplay="autoplay" muted="muted" loop="true">
            <source src="@/assets/video/video.webm" type="video/webm" />
          </video>
        </div>
      </div>
      <div id="fb5-ajax" data-cat="原文會" data-template="true">
        <div class="fb5" id="fb5">
          <section id="config">
            <ul>
              <li data-key="page_width" key="page_width">550</li>
              <li data-key="page_height" key="page_height">770</li>
              <li data-key="otopage_width" key="otopage_width">25</li>
              <li data-key="zoom_double_click" key="zoom_double_click">0</li>
              <li data-key="zoom_step" key="zoom_step">0.06</li>
              <li data-key="toolbar_visible" key="toolbar_visible">false</li>
              <li data-key="tooltip_visible" key="tooltip_visible">false</li>
              <li data-key="deeplinking_enabled" key="deeplinking_enabled">
                false
              </li>
              <li data-key="lazy_loading_pages" key="lazy_loading_pages">
                true
              </li>
              <li data-key="lazy_loading_thumbs" key="lazy_loading_thumbs">
                true
              </li>
              <li data-key="double_click_enabled" key="double_click_enabled">
                false
              </li>
              <li data-key="rtl" key="rtl">false</li>
              <li data-key="pdf_url" key="pdf_url"></li>
              <li data-key="pdf_scale" key="pdf_scale">1</li>
              <li data-key="page_mode" key="page_mode">auto</li>
              <li data-key="sound_sheet" key="sound_sheet"></li>
            </ul>
          </section>
          <div id="fb5-container-book">
            <div id="fb5-book">
              <div class="page">
                <img
                  src="@/assets/img/book_cover.png"
                  alt=""
                  draggable="false"
                />
              </div>
              <div class="page">
                <!-- style="background-image: url(@/assets/img/people_img_1_1.png)" -->
                <div
                  class="top-img"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_1_1.png') + ')',
                  }"
                ></div>
                <div class="page-sub-tit page-blue">
                  <div>
                    山海誌111
                    <br />
                    <span> HOME</span>
                  </div>
                </div>
                <div class="page-group">
                  <div class="page-txt">
                    季節為山谷調色，時而鮮豔、時而冷冽；四季的變換，在大地留下足跡，在每個人心中留下深刻的印記。
                    <router-link :to="areaLink1">了解更多</router-link>
                    <!-- <a href="###">
                        了解更多
                    </a> -->
                  </div>
                  <div class="page-video">
                    <!-- <img src="@/assets/img/people_video1.png" alt="alt" /> -->
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/UKMM6HQr1t4"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="page">
                <div
                  class="top-img half"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_1_2.png') + ')',
                  }"
                >
                  <div class="page-tit page-blue">
                    <p>山海誌<br />HOME</p>
                  </div>
                </div>
                <div class="page-txt2">
                  春天的盎然，夏季的綠意，秋收的景致，冬景的晨曦《山海誌》用鏡頭，收藏原鄉的美，也看見台灣的光。
                  <router-link class="page-blue" :to="areaLink1">
                    更多影片
                  </router-link>
                  <!-- <a
                    class="page-blue"
                    href="###"
                    >更多影片
                  </a> -->
                </div>
                <div class="ani-box ani-mountain"></div>
              </div>
              <div class="page">
                <div
                  class="top-img"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_2_1.png') + ')',
                  }"
                ></div>
                <div class="page-sub-tit page-red">
                  <div>
                    人物誌
                    <br />
                    <span>PEOPLE</span>
                  </div>
                </div>
                <div class="page-group">
                  <div class="page-txt">
                    我們的一生，用永不放棄的精神，追求自我、堅持到底，用我們的信念，述說我們的故事。
                    <router-link :to="areaLink2"> 了解更多 </router-link>
                    <!-- <a href="###">
                      了解更多
                    </a> -->
                  </div>
                  <div class="page-video">
                    <!-- <img src="@/assets/img/people_video2.png" alt="alt" /> -->
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/HcOfVvo8eAo"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="page">
                <div
                  class="top-img half"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_2_2.png') + ')',
                  }"
                >
                  <div class="page-tit page-red">
                    <p>人物誌<br />PEOPLE</p>
                  </div>
                </div>
                <div class="page-txt2">
                  遊子、異鄉人、山海的子民，深愛這塊土地，也深愛部落，他們靠雙手努力前進，《人物誌》用影像記錄下每個人充滿勇氣的身影。
                  <router-link class="page-red" :to="areaLink2">
                    更多影片
                  </router-link>
                  <!-- <a class="page-red" href="###">
                    更多影片
                  </a> -->
                </div>
                <div class="ani-box ani-people"></div>
              </div>
              <div class="page">
                <div
                  class="top-img"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_3_1.png') + ')',
                  }"
                ></div>
                <div class="page-sub-tit page-orange">
                  <div>
                    美食誌
                    <br />
                    <span>FOOD</span>
                  </div>
                </div>
                <div class="page-group">
                  <div class="page-txt">
                    每個人心中，都有一個名為「家」的味道，酸甜苦辣，都成了我們人生中無法忘卻的滋味。
                    <router-link :to="areaLink3">了解更多</router-link>
                    <!-- <a href="###">
                        了解更多
                    </a> -->
                  </div>
                  <div class="page-video">
                    <!-- <img src="@/assets/img/people_video1.png" alt="alt" /> -->
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/QOnWXOwsGdc"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="page">
                <div
                  class="top-img half"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_3_2.png') + ')',
                  }"
                >
                  <div class="page-tit page-orange">
                    <p>美食誌<br />FOOD</p>
                  </div>
                </div>
                <div class="page-txt2">
                  原住民懷抱敬意，在山海間，發展自己的飲食文化；藉由烹調，保留傳統、藉由佳餚，傳承智慧。
                  <router-link class="page-orange" :to="areaLink3">
                    更多影片
                  </router-link>
                  <!-- <a class="page-orange" href="###">
                      更多影片
                  </a> -->
                </div>
                <div class="ani-box ani-food"></div>
              </div>
              <div class="page">
                <div
                  class="top-img"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_4_1.png') + ')',
                  }"
                ></div>
                <div class="page-sub-tit page-purple">
                  <div>
                    族語誌
                    <br />
                    <span>LANGUAGE</span>
                  </div>
                </div>
                <div class="page-group">
                  <div class="page-txt">
                    不同的專業、不同的日常用語，我們讓族語生活化，讓母語不再陌生。
                    <router-link :to="areaLink4">了解更多</router-link>
                    <!-- <a href="###">
                        了解更多
                    </a> -->
                  </div>
                  <div class="page-video">
                    <!-- <img src="@/assets/img/people_video1.png" alt="alt" /> -->
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/vRmRbCHqq24"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="page">
                <div
                  class="top-img half"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_4_2.png') + ')',
                  }"
                >
                  <div class="page-tit page-purple">
                    <p>族語族<br />LANGUAGE</p>
                  </div>
                </div>
                <div class="page-txt2">
                  從編織到雕刻，從老師到木工；從中部阿美語到巒群布農語，從撒奇萊雅族語到拉阿魯哇族語《百工說族語》從部落母語出發，講生活用語，也講我們的歷史。
                  <router-link class="page-purple" :to="areaLink4">
                    更多影片
                  </router-link>
                  <!-- <a class="page-purple" href="###">
                    更多影片
                  </a> -->
                </div>
                <div class="ani-box ani-language"></div>
              </div>
              <div class="page">
                <div
                  class="top-img"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_5_1.png') + ')',
                  }"
                ></div>
                <div class="page-sub-tit page-green">
                  <div>
                    植物誌
                    <br />
                    <span>PLANT</span>
                  </div>
                </div>
                <div class="page-group">
                  <div class="page-txt">
                    這座島嶼有16個原住民族，有不同的風土民情與文化，植物代表的意義更是充滿差異。
                    <router-link :to="areaLink5">了解更多</router-link>
                    <!-- <a href="###">了解更多</a> -->
                  </div>
                  <div class="page-video">
                    <!-- <img src="@/assets/img/people_video1.png" alt="alt" /> -->
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/KiIXTF9J_Qo"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="page">
                <div
                  class="top-img half"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_5_2.png') + ')',
                  }"
                >
                  <div class="page-tit page-green">
                    <p>植物誌<br />PLANT</p>
                  </div>
                </div>
                <div class="page-txt2">
                  有些植物，對於我們來說，是神聖的，是象徵的，是實用的；藉著影像，《植物誌》紀錄下原住民族對於植物知識的傳承與運用。
                  <router-link class="page-green" :to="areaLink5">
                    更多影片
                  </router-link>
                  <!-- <a class="page-green" href="###">
                      更多影片
                  </a> -->
                </div>
                <div class="ani-box ani-plant"></div>
              </div>
              <div class="page">
                <div
                  class="top-img"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_6_1.png') + ')',
                  }"
                ></div>
                <div class="page-sub-tit page-brown">
                  <div>
                    部落誌
                    <br />
                    <span>COMMUNITY</span>
                  </div>
                </div>
                <div class="page-group">
                  <div class="page-txt">
                    原住民族的歷史，是這塊土地重要的根源，島上的16族，各自發展，讓部落不僅是家，更是產業推手。
                    <router-link :to="areaLink6">了解更多</router-link>
                    <!-- <a href="###">了解更多</a> -->
                  </div>
                  <div class="page-video">
                    <!-- <img src="@/assets/img/people_video1.png" alt="alt" /> -->
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/dKvx16fmN28"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="page">
                <div
                  class="top-img half"
                  :style="{
                    'background-image':
                      'url(' + require('@/assets/img/people_img_6_2.png') + ')',
                  }"
                >
                  <div class="page-tit page-brown">
                    <p>部落誌<br />COMMUNITY</p>
                  </div>
                </div>
                <div class="page-txt2">
                  《部落誌》盼以人為本，保存台灣原住民族文化，《部落誌》盼不忘初衷，持續為台灣原住民發聲。
                  <router-link class="page-brown" :to="areaLink6">
                    更多影片
                  </router-link>
                  <!-- <a class="page-brown" href="###">
                      更多影片
                  </a> -->
                </div>
                <div class="ani-box ani-tribe"></div>
              </div>
              <div class="page">
                <div class="go-start">返回首頁</div>
                <img
                  src="@/assets/img/book_cover_last.png"
                  alt=""
                  draggable="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="color-bg"></div>
      <div class="video-full-box">
        <video
          class="video-full-bg"
          autoplay="autoplay"
          muted="muted"
          loop="true"
        >
          <source src="@/assets/video/video.webm" type="video/webm" />
        </video>
      </div>
    </section>
  </div>
</template>
<script>
import NavArea from "@/components/NavArea.vue";
import Loading from "@/components/Loading.vue";
import Logo from "@/components/Logo.vue";

export default {
  data() {
    return {
      isLoad: true,
      areaLink1: "/InfoList/Mountain",
      areaLink2: "/InfoList/People",
      areaLink3: "/InfoList/Food",
      areaLink4: "/InfoList/Language",
      areaLink5: "/InfoList/Plant",
      areaLink6: "/InfoList/Tribe",
    };
  },
  mounted() {
    // console.log(window.countTest(1, 2));
    // console.log(window.aF);
    // console.log(Ajax_v5.all_books_slug);
    window.startBook();
    window.HomePageLoad();
    window.navClick();
    console.log("test");
    //=== 關閉Load ===
    this.isLoad = false;

    //redirect from load_book_lightbox ( change attributes href to onClick )
    // $("a[href]").each(function (index, element) {
    //   var el = $(element);
    //   var href = el.attr("href");
    //   if (href.indexOf("load_book_lightbox") >= 0) {
    //     el.attr("onClick", href);
    //     el.removeAttr("href");
    //     el.css("cursor", "pointer");
    //   }
    // });
    // if ($("#fb5-ajax").attr("data-cat") == undefined) {
    //   ///as lightbox
    //   window.bf();
    //   //   Ajax_v5.start_book_lightbox();
    // } else {
    //   //as shortcodes
    //   if ($("#fb5-ajax").hasClass("fb5-fullbrowser")) {
    //     $("#fb5-ajax").detach().appendTo("body");
    //   }
    //   window.aF();
    //   // Ajax_v5.start_book_shortcode();
    // }
    // // if (general.browser_firefox()) {
    // if (window.cF()) {
    //   console.log("book:version jquery = " + $.fn.jquery);
    // }
  },
  components: {
    NavArea,
    Loading,
    Logo,
  },
};
</script>
