<template>
  <nav id="navBox">
    <div class="stop-double-click"></div>
    <div class="nav-area" id="navBtn">
      <div class="nav-switch">
        <div></div>
      </div>
    </div>
    <div class="search">
      <div class="search-bg">
        <input type="text" placeholder="搜尋..." v-model="srcTxt" />
        <div class="btn-search" @click="srcBtn">
          <img src="@/assets/img/search.svg" alt="" />
        </div>
      </div>
    </div>
    <!-- 山海誌:Mountain 人物誌:People 美食誌:Food 族語誌:Language 植物誌:Plant 部落誌:Tribe-->
    <div class="nav-group">
      <router-link to="/InfoList/Mountain">
        山海誌<span>HOME</span>
      </router-link>
      <router-link to="/InfoList/People">
        人物誌<span>PEOPLE</span>
      </router-link>
      <router-link to="/InfoList/Food"> 美食誌<span>FOOD</span> </router-link>
      <router-link to="/InfoList/Language">
        族語誌<span>LANGUAGE</span>
      </router-link>
      <router-link to="/InfoList/Plant"> 植物誌<span>PLANT</span> </router-link>
      <router-link to="/InfoList/Tribe">
        部落誌<span>COMMUNITY</span>
      </router-link>
      <!-- <a href="page.html"></a>
      <a href="food.html"></a>
      <a href="language.html"></a>
      <a href="plant.html">植物誌<span>PLANT LOG</span></a>
      <a href="tribe.html">部落誌<span>TRIBE LOG</span></a> -->
      <div class="media-group">
        <a href="#"><img src="@/assets/img/icon_fb.svg" alt="" /></a>
        <!-- <a href="#"><img src="@/assets/img/icon_ig.svg" alt="" ></a> -->
        <!-- <a href="#"><img src="@/assets/img/icon_tw.svg" alt="" ></a> -->
        <a href="#"><img src="@/assets/img/icon_youtube.svg" alt="" /></a>
        <!-- <a href="#"><img src="@/assets/img/icon_g_plus.svg" alt="" ></a> -->
      </div>
    </div>
    <div class="copyright">
      財團法人原住民族文化事業基金會 版權所有<br />
      © 2020 Indigenous Peoples Cultural Foundation<br />
      電話：(02)2788-1600 傳真：(02)2788-1500<br />
      地址：台北市南港區重陽路120號5樓
    </div>
  </nav>
</template>
<script>
export default {
  name: "Nav",
  data() {
    return {
      srcTxt: "",
    };
  },
  methods: {
    srcBtn() {
      if (this.srcTxt) {
        this.$router.push({ path: "/Src", query: { src: this.srcTxt } });
      }
    },
  },
};
</script>
